import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import swal from "sweetalert";
import {useHistory} from "react-router-dom";

import {deleteUser, getUsers} from "../../../api";
import * as apiActions from "../../../state/users";
import moment from 'moment';

import {PageLayout} from "../../../components/pageLayout";

import userDefaultImg from "../../../resources/img/user/user-img-default.png";
import {hideLoader, showLoader} from '../../../utils/loader';
import {usePagination, useSortBy, useTable} from 'react-table';
import i18n from "i18next";
import { FortressTable } from "../shared/table";


export function AllUsers() {
    const [error, setError] = useState("");
    const allData = useSelector((state) => state.users.byId);
    const allIds = useSelector((state) => state.users.allIds);
    const allDataArray = allIds.map((key) => allData[key]);
    const companyState = useSelector((state) => state.company.profile);
    const [view, setView] = useState("table");
    const dispatch = useDispatch();
    const columns = [
            {
                Header: '',
                accessor: 'user',
                image: true
            },
            {
                Header: i18n.t('table.users.header.name', "Name")
                ,
                accessor: 'name',
            },
            {
                Header: i18n.t('table.users.header.emp-no', "Emp No"),
                accessor: 'emp',
            },
            {
                Header: i18n.t('table.users.header.email', "Email"),
                accessor: 'email'
            },
            {
                Header: i18n.t('table.users.header.role', "Role"),
                accessor: 'role',
            },
            {
                Header: i18n.t('table.users.header.status', "Status"),
                accessor: 'status',
            },
            {
                Header: i18n.t('table.users.header.emergency', "Emergency"),
                accessor: 'emergency',
            }
        ]
    ;
    const views = {
        table: {
            icon: "table-large",
        },
        grid: {
            icon: "border-outside"
        }
    }
    useEffect(async () => {
        setError("");
        loadAllUsers();
    }, []);

    async function loadAllUsers() {
        showLoader();
        const res = await getUsers(companyState.id, false);
        if (res.success) {
            dispatch(apiActions.clearUsers());
            dispatch(apiActions.setUsers(res.data));
        }
        hideLoader();
    }

    useEffect(() => {
        if (error) {
            swal(error);
        }
    }, [error]);
    let history = useHistory();

    function editUser(user) {

        history.push({
            pathname: '/users/edit',
            search: '?id=' + user.id
        })
    }

    function removeUser(user,force,message) {
      if(!message){
        message='You cannot undo this. Are you sure to remove "' + user.user.firstName + '" ?';
      }

        swal(
            {
                title: 'Confirm',
                text: message,
                icon: 'warning',
                buttons: {
                    cancel: "Close",
                    submit: {
                        text: "Delete",
                        value: "delete"
                    }
                }

            }
        ).then((val) => {
            if (val == 'delete') {
                return deleteUser(user.id,force);

            }
            throw null;
        }).then((res) => {

            if (res && !res.success && res.code && res.code.indexOf("C") != -1) {
              removeUser(user,true,res.message+". Do you still want to remove this user?")
            } else {

                swal({
                    title: res && res.success ? 'Success' : 'Error',
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: res && res.success ? 'success' : 'error',
                    buttons: "Close"
                }).then(() => {

                        loadAllUsers();

                    }
                );
            }
        })
    }

    function getStatus(status, time) {
        if (!status || status === 'ONLINE') {
            return "Online";
        }
        if (!time) {
            return status;
        }
        return "Online " + moment(time).fromNow();
    }

    return (
        <PageLayout>
            <div className="content-wrapper">
              
                <div className="row">

                    <div className="col-12">
                       <FortressTable
                                data={
                                    allDataArray? allDataArray.map((user) => {
                                            return {
                                                user: user.imageURL ? user.imageURL : userDefaultImg,
                                                name: user.name,
                                                email: user.user.email,
                                                role: user.userRole.name,
                                                status: getStatus(user.userStatus, user.statusUpdatedTime),
                                                emergency: user.availableForEmergency ? "Available" : "N/A",
                                                data: user,
                                                emp: user.employeeNumber,
                                                edit: () => editUser(user),
                                                remove: () => removeUser(user)
                                            }
                                        }
                                    ):[]
                                }
                                columns={columns}

                            ></FortressTable>

                    </div>
                    

                </div>
            </div>
        </PageLayout>
    );

}
