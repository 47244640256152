import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

import { getJobLocations,removeJobLocation } from "../../../api";
import * as apiActions from "../../../state/locations";

import { PageLayout } from "../../../components/pageLayout";

import locationDefaultImg from "../../../resources/img/location/location.png";
import { useHistory } from "react-router";
import { FortressTable } from "../shared/table";
import i18n from "i18next";


export function ViewJobLocations() {
  const [error, setError] = useState("");
  const allData = useSelector((state) => state.locations.byId);
  const allIds = useSelector((state) => state.locations.allIds);
  const allDataArray = allIds.map((key) => allData[key]);
  const companyState = useSelector((state) => state.company.profile);
  const selectedClientId = null;
  const page = 0;
  const size = 100;
  const dispatch = useDispatch();
  const columns = [
   
    {
      Header: i18n.t('table.jobLocation.header.image', "")
      ,
      image: true,
      accessor: 'img',
  },
    {
        Header: i18n.t('table.jobLocation.header.name', "Name")
        ,
        accessor: 'name',
    },
    {
        Header: i18n.t('table.jobLocation.header.address', "Address"),
        accessor: 'address',
        
    },
    {
        Header: i18n.t('table.jobLocation.header.website', "Website"),
        accessor: 'web'
    },
    {
        Header: i18n.t('table.jobLocation.header.groups', "Groups"),
        accessor: 'groups',
        display: viewGroups
    }
];
  useEffect(async () => {
    setError("");

    loadAllJobLocations();
  }, []);

function viewGroups(locationData){
  const clsName="nav-link count-indicator  d-flex justify-content-center align-items-center";
  const disabledClsName=clsName+" disabled";

    return (
      <>
      <a
      className={locationData.jobLocationGroups.length>0?clsName:disabledClsName}
      id="sitesDropdown"
      href="#"
      data-toggle="dropdown"
    >
       <button className="btn btn-xs btn-outline-secondary btn-rounded" >{locationData.jobLocationGroups.length} assigned group(s)</button>
      </a>
      {locationData.jobLocationGroups.length>0 && (
      <div
            className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
            style={{
              maxHeight: "700px",
              overflow: "auto"
            }}
            aria-labelledby="sitesDropdown"
          >
                
            <p className="mb-0 font-weight-normal float-left dropdown-header animate" >
          
                      { locationData.jobLocationGroups.map((group) => {
                        return (
                          <div className="preview-item-content flex-grow">
                          <small className="badge badge-secondary-outline badge-pill flex-grow">
                            {group.name
                            }
                          </small>
                          </div>
                        )
                      })}
                   
            </p>
          
            </div>   )}
      </>
   )
  
}


  let history = useHistory();

 async function loadAllJobLocations() {
    const res = await getJobLocations(companyState.id, selectedClientId, null, page, size);
    if (res.success) {
      dispatch(apiActions.clearLocations());
      dispatch(apiActions.setLocations(res.data));
    }
  }
  function editJobLocation(id) {
    history.push({
      pathname: '/jobs/addlocation/edit',
      search: '?id=' + id
    })
  }
  function _removeJobLocation(id) {
    const location=allData[id];
    swal(
      {
        title: 'Confirm',
        text: 'You cannot undo this. Are you sure to remove "' + location.name + '" ?',
        icon: 'warning',
        buttons: {
          cancel: "Close",
          submit: {
            text: "Delete",
            value: "delete"
          }
        }

      }
    ).then((val) => {
      if (val == 'delete') {
        return removeJobLocation(id);

      }
      throw null;
    }).then((res) => {
      swal({
        title: res && res.success ? 'Success' : 'Error',
        text: res && res.message ? res.message : "Something went wrong",
        icon: res && res.success ? 'success' : 'error',
        buttons: "Close"
      }).then(() => {

        loadAllJobLocations();

      }
      );
    })
  }
  return (
    <PageLayout>
      <div className="content-wrapper">
        <div className="row">

<FortressTable
columns={columns}
data={allDataArray?allDataArray.map((locationData) => {
  return{
    img:locationData.imageURL && locationData.imageURL.includes("http") ? locationData.imageURL : locationDefaultImg,
    name:locationData.name,
    address:locationData.address,
    web: locationData.website,
    data: locationData,
    edit: () => editJobLocation(locationData.id),
    remove: () =>_removeJobLocation(locationData.id) 
  }
}):[]}
></FortressTable>

        </div>
      </div>
    </PageLayout>
  );
}
