import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

import { getUsers, deleteUser, getContracts,getContract ,removeContract} from "../../../api";
import * as apiActions from "../../../state/users";
import {usePagination, useSortBy, useTable} from 'react-table';
import { PageLayout } from "../../../components/pageLayout";
import {FortressTable} from "../shared/table"
import userDefaultImg from "../../../resources/img/contract/contract.png";
import i18n from "i18next";

export function AllContracts() {
  const [error, setError] = useState("");
  const companyState = useSelector((state) => state.company.profile);
  const [contracts, setContracts] = useState([]);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.byId);
  const [open, setOpen] = useState(false);
  const columns = [
   
    {
      Header: i18n.t('table.contract.header.image', "")
      ,
      image: true,
      accessor: 'img',
  },
    {
        Header: i18n.t('table.contract.header.name', "Name")
        ,
        accessor: 'name',
    },
    {
        Header: i18n.t('table.contract.header.description', "Description"),
        accessor: 'desc',
        display: function(contract){
          return <p className="text-muted single-line-text max100Chars" title={contract.description} >
          {contract.description}
        </p>

        }
    },
    {
        Header: i18n.t('table.contract.header.startDate', "Start Date"),
        accessor: 'start'
    },
    {
        Header: i18n.t('table.contract.header.endDate', "End Date"),
        accessor: 'end',
    },
    {
        Header: i18n.t('table.contract.header.status', "Status"),
        accessor: 'status',
        display: viewStatus
    },
    {
        Header: i18n.t('table.contract.header.sites', "Sites"),
        accessor: 'sites',
        style: {maxWidth: '200px'},
        display: viewSites
    }
];

function viewStatus(contract)  { 
  return   contract.active ? (
    <small className="badge badge-success-outline badge-pill">
      {i18n.t('contract.view.active',"Active")}
    </small>
  ) :
    contract.startDate && new Date() < new Date(contract.startDate) ?
      (
        <small className="badge badge-info-outline badge-pill">
          {i18n.t('contract.view.not-active-yet',"Not active yet")}

        </small>
      )
      :
      (
        <small className="badge badge-danger-outline badge-pill">
          {i18n.t('contract.view.expired',"Expired")}
        </small>
      )
}
function viewSites(contract){
  const clsName="nav-link count-indicator  d-flex justify-content-center align-items-center";
  const disabledClsName=clsName+" disabled";
  return (
    <>
    <a
    className={contract.jobLocations.length>0 ? clsName:disabledClsName}
    id="sitesDropdown"
    href="#"
    data-toggle="dropdown"
  >
     <button className="btn btn-xs btn-outline-secondary btn-rounded" onClick={()=> {
    viewSites(contract);
  }}>{contract.jobLocations.length} assigned site(s)</button>
    </a>
    {contract.jobLocations.length>0 && (
    <div
          className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
          style={{
            maxHeight: "700px",
            overflow: "auto"
          }}
          aria-labelledby="sitesDropdown"
        >
              
          <p className="mb-0 font-weight-normal float-left dropdown-header animate" >
        
                    {contract.jobLocations.map((loc) => {
                      return (
                        <div className="preview-item-content flex-grow">
                        <small className="badge badge-secondary-outline badge-pill flex-grow">
                          {loc.name
                          }
                        </small>
                        </div>
                      )
                    })}
                 
          </p>
        
          </div>   )}
    </>
 )
}


  useEffect(async () => {
    setError("");
    loadAllContracts();
  }, []);

  async function loadAllContracts() {
    const res = await getContracts(null, null, companyState.id, true);
    if (res.success) {
      setContracts(res.data);
    }

  }

  useEffect(() => {
    if (error) {
      swal(error);
    }
  }, [error]);
  let history = useHistory();
  function editUser(user) {

    history.push({
      pathname: '/users/edit',
      search: '?id=' + user.id
    })
  }
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  function editContract(contract) {

    history.push({
      pathname: '/contracts/edit',
      search: '?id=' + contract.id
    })
  }

  function _removeContract(contract) {
    //console.log((contract);)
    swal(
      {
        title: 'Confirm',
        text: 'You cannot undo this. Are you sure to remove "' + contract.title + '" ?',
        icon: 'warning',
        buttons: {
          cancel: "Close",
          submit: {
            text: "Delete",
            value: "delete"
          }
        }

      }
    ).then((val) => {
      if (val == 'delete') {
        return removeContract(contract.id);

      }
      throw null;
    }).then((res) => {
      swal({
        title: res && res.success ? 'Success' : 'Error',
        text: res && res.message ? res.message : "Something went wrong",
        icon: res && res.success ? 'success' : 'error',
        buttons: "Close"
      }).then(() => {

         loadAllContracts();

      }
      );
    })
  }
  return (
    <PageLayout>
      <div className="content-wrapper">
        <div className="row">

        <FortressTable
                                data={
                                  contracts? contracts.map((contract) => {
                                            return {
                                                img: users[contract.clientId] && users[contract.clientId].user.imageURL ? users[contract.clientId].user.imageURL : userDefaultImg,
                                                name: contract.title,
                                                desc: contract.description,
                                                start: formatDate(contract.startDate),
                                                status: contract.active,
                                                end: formatDate(contract.endDate),
                                                data: contract,
                                                edit: () => {
                                                    editContract(contract);
                                                  },
                                                  remove:()=>{
                                                    _removeContract(contract);
                                                  }
                                            }
                                        }
                                    ):[]
                                }
                                columns={columns}

                            ></FortressTable>

          {/* {contracts.map((contract) => {
            return (
              <div className="col-sm-12 col-md-6 col-xl-4 grid-margin stretch-card user-card" key={contract.id}>
                <div className="card mb-3">
                  <div className="row no-gutters">
                    <div className="col-4">
                      <img
                        src={
                          users[contract.clientId] && users[contract.clientId].user.imageURL ? users[contract.clientId].user.imageURL : userDefaultImg
                        }
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="col-8">
                      <div className="card-body overflow-y-auto" style={{
                        maxHeight: "200px",
                        objectFit: "cover",
                        overflowY: "auto"
                      }}>


                        <div className="pos-abs r-2 t-2 action-btns">
                          <button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                            onClick={() => editContract(contract)}
                          ><i className="mdi mdi-pencil"></i></button>
                          <button className="btn btn-sm btn-outline-danger btn-fw p-2"
                            onClick={() => _removeContract(contract)}
                          ><i className="mdi mdi-delete"></i></button>

                        </div>

                        <h4 className="card-title">
                          {contract.title}
                          {contract.active && (
                            <small className="mdi mdi-checkbox-marked-circle user-verified-tick"></small>
                          )}
                          <span className="ml-2 mb-2">
                            {contract.active ? (
                              <small className="badge badge-success-outline badge-pill">
                                {i18n.t('contract.view.active',"Active")}
                              </small>
                            ) :
                              contract.startDate && new Date() < new Date(contract.startDate) ?
                                (
                                  <small className="badge badge-info-outline badge-pill">
                                    {i18n.t('contract.view.not-active-yet',"Not active yet")}

                                  </small>
                                )
                                :
                                (
                                  <small className="badge badge-danger-outline badge-pill">
                                    {i18n.t('contract.view.expired',"Expired")}
                                  </small>
                                )}
                          </span>

                        </h4>
                        <p className="text-muted single-line-text" title={contract.description} >
                          {contract.description}
                        </p>

                        <p className="card-text">{"Start: " + formatDate(contract.startDate)}</p>
                        <p className="card-text">{"End: " + formatDate(contract.endDate)}</p>
                        <div>
                          {contract.jobLocations && (
                            contract.jobLocations.map((loc) => {
                              return (
                                <small className="badge badge-secondary-outline badge-pill">
                                  {loc.name
                                  }
                                </small>
                              )
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </PageLayout>
  );



}


