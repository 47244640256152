import {usePagination, useSortBy, useTable} from 'react-table';

export function FortressTable({data,columns, totalPageSize} ) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageSize: totalPageSize??50}
        },
        useSortBy,
        usePagination
    )

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case

    return (
        < div className="table-responsive">
            <table className="table table-striped table-compact" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>
                  {column.isSorted
                      ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                      : ''}
                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} >
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} style={cell.column.style} >
                                            {cell.column.display? cell.column.display(row.original.data):
                                            
                                            cell.column.image ? (<img className="card-img-top  avatar-img"
                                                                       src={
                                                                           cell.value
                                                                       }
                                            >

                                            </img>) : cell.render('Cell')}


                                        </td>

                                    )
                                })}
                                <td>
                                    <div className=" r-2 t-2 ">
                                       {row.original.edit && (<button className="btn btn-sm btn-outline-primary btn-fw p-2 mr-1"
                                                onClick={row.original.edit}
                                        ><i className="mdi mdi-lead-pencil"></i></button>)}


{row.original.remove && 
                                        (<button className="btn btn-sm btn-outline-danger btn-fw p-2"
                                                onClick={row.original.remove}
                                        ><i className="mdi mdi-delete"></i></button>)}

                                    </div>
                                </td>
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            <div className="row justify-content-md-end flex">
                <div className="col-md-7">
                    <div className="pagination  justify-content-md-end d-flex">
                        <nav>
                            <ul className="pagination flex-wrap">
                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => gotoPage(0)}
                                            disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => previousPage()}
                                            disabled={!canPreviousPage}>
                                        {'<'}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => nextPage()}
                                            disabled={!canNextPage}>
                                        {'>'}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => gotoPage(pageCount - 1)}
                                            disabled={!canNextPage}>
                                        {'>>'}
                                    </button>
                                </li>


                            </ul>
                        </nav>

                    </div>


                </div>

                <div className="col-md-3">
        <span>
          Page{' '}
            <input
                className=" form-control-sm col-md-5 "
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                }}
            />
          <strong>
            of {pageOptions.length}
          </strong>{' '}
        </span>

                </div>


            </div>


        </ div>
    )
}