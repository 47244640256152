import axios from "axios";
import LocalStorageService from "./utils/localStorageService";
import {hideAuthLoader, hideLoader, showAuthLoader, showLoader} from './utils/loader';

const localStorageService = LocalStorageService.getService();
const apiHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

const publicApiHandler = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

const SKIP_LOADING = 'SKIP_LOADING';
apiHandler.defaults.params = {};
apiHandler.interceptors.request.use(function (config) {
        if (!(config && config.headers && config.headers && config.headers[SKIP_LOADING])) {
            showLoader();
        }


        const token = localStorageService.getAccessToken();
        config.headers.Authorization = "Bearer " + token;
        if (config.url.indexOf("tenantId=") === -1 &&
            config.params && !config.params['tenantId'] && getTenantId()) {

            config.params['tenantId'] = getTenantId();

        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

apiHandler.interceptors.response.use((response) => {
        hideLoader();
        return response
    },
    async function (error) {

        //console.log((51, error.response);)
        if (error.config && error.response && error.response.status === 401 && !error.config._retry) {
            const originalRequest = error.config;
            originalRequest._retry = true;
            return apiHandler.post('/api/auth/refresh-token',
                {
                    "refreshToken": localStorageService.getRefreshToken()
                })
                .then(res => {
                    if (res.status === 200) {
                        // 1) put token to LocalStorage
                        localStorageService.setToken(res.data.data);

                        // 2) Change Authorization header
                        apiHandler.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();

                        // 3) return originalRequest object with Axios.
                        hideLoader();

                        return apiHandler.request(originalRequest);
                    }
                }).catch(err => {
                    //console.log(e(err);)
                })
        } else if (error.config && error.response && error.response.data && error.response.data.code == 'E038') {
            logout();
        }
        hideLoader();

        // return Error object with Promise
        return error.response;
    });

publicApiHandler.interceptors.request.use(function (config) {
    if (!(config && config.headers && config.headers && config.headers[SKIP_LOADING])) {
        showAuthLoader();
    }


    return config;
});

const logout = () => {
    sessionStorage.clear();
    localStorageService.clear();
    window.location.href = "/signin";
};

publicApiHandler.interceptors.response.use(function (response) {
        hideAuthLoader();
        return response
    },
    async function (error) {
        hideAuthLoader();

        return error.response;
    });

export async function validateCompany(tenantCode) {
    const response = await publicApiHandler.get(
        `/api/tenant/validate?tenantCode=${tenantCode}`
    );
    return response.data;
}

export async function signIn(email, password, tenant) {
    const response = await publicApiHandler.post("/api/auth/signin", {
        email,
        password,
        tenant,
        platform: 'WEB'

    });
    const data = response.data;
    if (data.success) {
        localStorageService.setToken(data.data);
        localStorageService.setTenantId(tenant);
    }
    return data;
}

export async function validatePasswordResetToken(token) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/user/password-reset/validate");
    url.searchParams.append("token", token);
    const response = await publicApiHandler.post(url.href);
    return response.data;
}

export async function forgortPasswordRequest(email) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/user/password-reset/request");
    url.searchParams.append("email", email);
    const response = await publicApiHandler.post(url.href);
    return response.data;
}

export async function resetPassword(request) {
    const response = await publicApiHandler.post("/api/user/password-reset", request);
    return response.data;
}

export async function currentUser() {
    const response = await apiHandler.get("/api/user/me");
    return response.data;
}

export async function updateLocation(lat, lang) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/user/location/current");
    if (lat != null) url.searchParams.append("lat", lat);
    if (lang != null) url.searchParams.append("lang", lang);
    url.searchParams.append("timezone", getTimeZone());
    const response = await apiHandler.put(url.href);
    return response.data;
}

export async function getUserRoles(tenant) {
    const response = await apiHandler.get(
        `/api/user/role/all?tenantId=${tenant}`
    );
    return response.data;
}

export async function signUp(
    user
) {
    const response = await apiHandler.post("/api/user/", user);
    return response.data;
}

export async function updateUser(
    userId, user
) {
    const response = await apiHandler.put("/api/user/" + userId, user);
    return response.data;
}

export async function deleteUser(
    userId,
    force
) {
    if (!force) {
        force = false;
    }
    const response = await apiHandler.delete("/api/user/" + userId + "?force=" + force);
    return response.data;
}

export async function uploadProfilePicture(
    tenantUserId, image
) {
    const formData = new FormData();
    formData.append('image', image);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await apiHandler.put("/api/user/tenant/" + tenantUserId + "/profile-picture", formData, config);
    return response.data;
}

export async function getAccessToken(token) {
    const response = await publicApiHandler.post("/api/auth/refresh-token", {
        refreshToken: token,
        platform: 'WEB'
    });
    return response;
}

export async function registerDevice(token, devicename, userid) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/user/tenant/" + userid + "/device");
    if (devicename) url.searchParams.append("deviceName", devicename);
    url.searchParams.append("deviceToken", token);
    const response = await apiHandler.post(url.href);
    return response.data;
}


export async function getUsers(tenant, clientsOnly) {
    const response = await apiHandler.get(`/api/user/tenant?clientsOnly=${clientsOnly}&tenantId=${tenant}`);
    return response.data;
}

export async function getGroups(tenant) {
    const response = await apiHandler.get(`/api/group/location/all?tenantId=${tenant}`);
    return response.data;
}

export async function deleteGroup(groupId) {
    const response = await apiHandler.delete(`/api/group/location/` + groupId);
    return response.data;
}

export async function registerGroup(data) {
    const response = await apiHandler.post(`/api/group/location`, data);
    return response.data;
}

export async function updateGroup(groupId, data) {
    const response = await apiHandler.put(`/api/group/location/` + groupId, data);
    return response.data;
}

export async function getCompanyById(tenantUserId, deviceName, deviceToken) {
    const response = await apiHandler.post(
        `/api/user/tenant/${tenantUserId}/device?deviceName=${deviceName}&deviceToken=${deviceToken}`
    );
    return response.data;
}

export async function createContract(data) {
    const response = await apiHandler.post("/api/client/contract", data);
    return response.data;
}


export async function updateContract(contractId, data) {
    const response = await apiHandler.put("/api/client/contract/" + contractId, data);
    return response.data;
}

export async function getContracts(active, clientId, tenantId, includeLocations) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/client/contract/all");
    url.searchParams.append("tenantId", tenantId);
    if (clientId != null) url.searchParams.append("clientId", clientId);
    if (active != null) url.searchParams.append("active", active);
    if (includeLocations != null) url.searchParams.append("includeLocations", includeLocations);
    const response = await apiHandler.get(url.href);

    return response.data;
}

export async function getContract(contractId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/client/contract/" + contractId);
    const response = await apiHandler.get(url.href);

    return response.data;
}

export async function removeContract(contractId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/client/contract/" + contractId);
    const response = await apiHandler.delete(url.href);

    return response.data;
}


export async function createJobLocation(data) {
    const response = await apiHandler.post("/api/job/location", data);
    return response.data;
}

export async function updateJobLocation(id, data) {
    const response = await apiHandler.put("/api/job/location/" + id, data);
    return response.data;
}

export async function uploadJobLocationImage(
    jobLocationId, image
) {
    const formData = new FormData();
    formData.append('image', image);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await apiHandler.put("/api/job/location/" + jobLocationId + "/image", formData, config);
    return response.data;
}

export async function getJobLocations(tenantId, clientId, contractId, page, size) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/location");
    url.searchParams.append("tenantId", tenantId);
    if (clientId != null) url.searchParams.append("clientId", clientId);
    if (contractId != null) url.searchParams.append("contractId", contractId);
    if (page != null) url.searchParams.append("page", page);
    if (size != null) url.searchParams.append("size", size);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function removeJobLocation(
    jobLocationId
) {
    const response = await apiHandler.delete("/api/job/location/" + jobLocationId);
    return response.data;
}

export async function createJobDetail(data) {
    const response = await apiHandler.post("/api/job/detail", data);
    return response.data;
}

export async function getJobDetails(tenantId, clientId, page, size) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/detail");
    url.searchParams.append("clientId", clientId);
    url.searchParams.append("tenantId", tenantId);
    if (page != null) url.searchParams.append("page", page);
    if (size != null) url.searchParams.append("size", size);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getJobDetailSummary(tenantId, clientId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/detail/summary");
    if (clientId != null) url.searchParams.append("clientId", clientId);
    url.searchParams.append("tenantId", tenantId);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getJobDetail(jobDetailId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/detail/" + jobDetailId);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function updateJobDetail(id, data) {
    const response = await apiHandler.put("/api/job/detail/" + id, data);
    return response.data;
}

export async function deleteJobDetail(id) {
    const response = await apiHandler.delete("/api/job/detail/" + id);
    return response.data;
}


export async function createJob(data) {
    const response = await apiHandler.post(process.env.REACT_APP_API_BASE + "/api/job/", data);
    return response.data;
}


export async function updateJob(jobid, data) {
    const response = await apiHandler.put(process.env.REACT_APP_API_BASE + "/api/job/" + jobid, data);
    return response.data;
}

export async function registerUsersForJob(jobId, data) {
    const response = await apiHandler.post(process.env.REACT_APP_API_BASE + "/api/job/" + jobId + "/user", data);
    return response.data;
}

export async function updateUsersForJobApi(jobId, data) {
    const response = await apiHandler.post(process.env.REACT_APP_API_BASE + "/api/job/" + jobId + "/user", data);
    return response.data;
}

export async function removeUsersForJob(jobId, userIds) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/" + jobId + "/user");
    if (userIds != null) {
        userIds.forEach((e) => {
            url.searchParams.append("userIds", e);
        })
    }
    const response = await apiHandler.delete(url.href);
    return response.data;
}

export async function getJobs(tenantId, clientId, jobLocationId, jobDetailId, page, size, isActive) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/all");
    url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("mustIncludeMe", false);
    if (clientId != null) url.searchParams.append("clientId", clientId);
    if (jobDetailId != null) url.searchParams.append("jobDetailId", jobDetailId);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (isActive != null) url.searchParams.append("isActive", isActive);

    if (page != null) url.searchParams.append("page", page);
    if (size != null) url.searchParams.append("size", size);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getJob(jobId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/" + jobId);
    url.searchParams.append("includeUsers", true);
    url.searchParams.append("includeQuestions", false);
    url.searchParams.append("includeJobDetail", false);
    const response = await apiHandler.get(url.href);
    return response.data;
}


export async function setJobActiveState(jobId, isActive) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/" + jobId + "/active-state");
    url.searchParams.append("isActive", isActive);
    const response = await apiHandler.put(url.href);
    return response.data;
}

export async function getConversations(locationId, tenantId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/conversation/");
    if (locationId) {
        url.searchParams.append("conversationMappedTable", "JOB_LOCATION");
        url.searchParams.append("mappedId", locationId);
    }
    if (tenantId != null) url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("mustIncludeMe", false);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getMessages(threadId, page, size) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/conversation/thread/" + threadId + '/message/all');
    url.searchParams.append("threadId", threadId);
    if (page != null) url.searchParams.append("page", page);
    if (size != null) url.searchParams.append("size", size);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function addMessage(conId, threadId, messageData) {
    const response = await apiHandler.post(process.env.REACT_APP_API_BASE + "/api/conversation/" + conId + '/thread/' + threadId + '/message',
        messageData);
    return response.data;
}

export async function getConversation(conId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/conversation/" + conId);
    url.searchParams.append("includeAssignees", true);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getDashboardCountSummary(tenantId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/count/summary");
    url.searchParams.append("tenantId", tenantId);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getJobTrend(tenantId, frequency) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/trend/job");
    url.searchParams.append("tenantId", tenantId);
    if (frequency != null) url.searchParams.append("frequency", frequency);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getUsersTrend(tenantId, frequency, userLevels) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/trend/user");
    url.searchParams.append("tenantId", tenantId);
    if (frequency != null) url.searchParams.append("frequency", frequency);
    if (userLevels != null) url.searchParams.append("userLevels", userLevels);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getWorkTrend(tenantId, frequency, jobId, jobLocationId, statuses, userId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/trend/work");
    url.searchParams.append("tenantId", tenantId);
    if (frequency != null) url.searchParams.append("frequency", frequency);
    if (jobId != null) url.searchParams.append("jobId", jobId);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (statuses != null) url.searchParams.append("statuses", statuses);
    if (userId != null) url.searchParams.append("userId", userId);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getTenantUserSummary(tenantId, emergencyAvailable, roles) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/user/tenant/summary");
    url.searchParams.append("tenantId", tenantId);
    if (emergencyAvailable != null) url.searchParams.append("emergencyAvailable", emergencyAvailable);
    if (roles != null) url.searchParams.append("roles", roles);
    const response = await apiHandler.get(url.href);
    return response.data;
}


export async function getWorks(tenantId, jobLocationId, startDate, endDate, status, jobSpecTypes) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/work/all");
    url.searchParams.append("tenantId", tenantId);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (status != null) url.searchParams.append("status", status);
    if (jobSpecTypes != null) url.searchParams.append("jobSpecTypes", jobSpecTypes);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getWork(workId, specType) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/work/" + workId + "/filled");
    if (specType != null) url.searchParams.append("specTypes", specType);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function workSummaryEdit(summary, workId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/work/" + workId + "/summary");
    const response = await apiHandler.put(url.href, summary);
    return response.data;
}

export async function getWorkSummary(workId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/work/" + workId + "/summary");
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getSitePayroll(tenantId, jobLocationId, startDate, endDate) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/work-time/location");
    url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("jobLocationId", jobLocationId);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getSitesPayroll(tenantId, startDate, endDate) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/work-time/location/all");
    url.searchParams.append("tenantId", tenantId);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getUserPayroll(tenantId, tenantUserId, groupIds, startDate, endDate) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/work-time/user");
    url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("tenantUserId", tenantUserId);

    if (groupIds != null && groupIds.length > 0) {
        groupIds.forEach((e) => {
            url.searchParams.append("groupIds", e);
        })
    }

    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getUsersPayroll(tenantId, startDate, endDate) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/work-time/user/all");
    url.searchParams.append("tenantId", tenantId);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export async function downloadPayrollReport(payrollType, tenantId, tenantUserId, jobLocationId, startDate, endDate, reportType) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/pdf/work-time");
    url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("payrollType", payrollType);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (tenantUserId != null) url.searchParams.append("tenantUserId", tenantUserId);
    if (reportType != null) url.searchParams.append("reportType", reportType);

    url.searchParams.append("timezone", getTimeZone());

    const response = await apiHandler.get(url.href);
    return response;
}

export async function downloadWorkDetailPdf(workId, jobSpecType, reportType) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/pdf/work/detail");
    url.searchParams.append("workId", workId);
    if (jobSpecType != null) url.searchParams.append("jobSpecType", jobSpecType);
    if (reportType != null) url.searchParams.append("reportType", reportType);

    url.searchParams.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);

    const response = await apiHandler.get(url.href);
    return response;
}

export async function getInspectionRatingTrend(jobId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/trend/inspection");
    url.searchParams.append("jobId", jobId);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getHeatMapData(tenantId, tenantUserId, jobLocationId, startDate, endDate, accuracy) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/heat-map");
    url.searchParams.append("tenantId", tenantId);
    if (tenantUserId != null) url.searchParams.append("tenantUserId", tenantUserId);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    if (accuracy != null) url.searchParams.append("accuracy", accuracy);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getPolyLineMapData(tenantId, workIds, tenantUserId, jobLocationId, startDate, endDate, accuracy) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/analytic/poly-line-map");
    url.searchParams.append("tenantId", tenantId);
    if (tenantUserId != null) url.searchParams.append("tenantUserId", tenantUserId);
    if (jobLocationId != null) url.searchParams.append("jobLocationId", jobLocationId);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    if (accuracy != null) url.searchParams.append("accuracy", accuracy);
    if (workIds != null) url.searchParams.append("workIds", workIds);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getWorkSummaries(tenantId, jobLocationId, fromDate, toDate) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/work/summary/all");
    url.searchParams.append("tenantId", tenantId);
    url.searchParams.append("jobLocationId", jobLocationId);
    if (fromDate != null) url.searchParams.append("fromDate", fromDate);
    if (toDate != null) url.searchParams.append("toDate", toDate);
    const response = await apiHandler.get(url.href);
    return response.data;
}

export async function getNotifications() {

    const url = new URL(process.env.REACT_APP_API_BASE + "/api/notification");
    var res = (await apiHandler.get(url.href, getSkipLoadingConf()));
    return res && res.data ? res.data : null;
}

export async function markNotificationsRead() {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/notification/read");
    return await apiHandler.put(url.href).data;
}


export async function getNotificationConfig(tenantId, mappedId, notificationCategory) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/notification/config");

    if (notificationCategory != null) url.searchParams.append("notificationCategory", notificationCategory);
    if (mappedId != null) url.searchParams.append("mappedId", mappedId);
    url.searchParams.append("tenantId", tenantId);
    const data = await apiHandler.get(url.href);
    return data.data;
}

export async function notificationSubscribe(data) {
    const response = await apiHandler.post(process.env.REACT_APP_API_BASE + "/api/notification/subscribe", data);
    return response.data;
}

export async function getCalendarEvents({
                                            tenantId,
                                            calendarEventTypes,
                                            tenantUserIds,
                                            startDate,
                                            endDate,
                                            active,
                                            shouldPrivate,
                                            mappedIds
                                        }) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/calendar/event/all");

    if (tenantId != null) url.searchParams.append("tenantId", tenantId);
    if (calendarEventTypes != null) url.searchParams.append("calendarEventTypes", calendarEventTypes);
    if (tenantUserIds != null) url.searchParams.append("tenantUserIds", tenantUserIds);
    if (startDate != null) url.searchParams.append("startDate", startDate);
    if (endDate != null) url.searchParams.append("endDate", endDate);
    if (active != null) url.searchParams.append("active", active);
    if (shouldPrivate != null) url.searchParams.append("shouldPrivate", shouldPrivate);
    if (mappedIds != null) url.searchParams.append("mappedIds", mappedIds);
    const data = await apiHandler.get(url.href);
    return data.data;

}

function getSkipLoadingConf() {
    return {
        headers: {
            SKIP_LOADING: SKIP_LOADING
        }
    }
}

export async function loadLanguageData(tenantId, language) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/language");

    if (tenantId != null) url.searchParams.append("tenantId", tenantId);
    if (language != null) url.searchParams.append("language", language);
    const data = await publicApiHandler.get(url.href, getSkipLoadingConf());
    return data.data;
}

export async function removeJobRelatedFile(fileId) {
    const response = await apiHandler.delete(`/api/job/related-file/` + fileId);
    return response.data;

}

export async function uploadJobRelatedFile(
    jobId, file
) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('jobId', jobId);
    formData.append('tenantId', getTenantId());
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await apiHandler.post("/api/job/related-file", formData, config);
    return response.data;
}

export async function getJobRelatedFiles(jobId) {
    const url = new URL(process.env.REACT_APP_API_BASE + "/api/job/related-file");

    if (jobId != null) url.searchParams.append("jobId", jobId);
    const data = await apiHandler.get(url.href);
    return data.data;
}

function getTenantId() {
    return localStorageService.getTenantId();
}